import React from 'react';
import { FormattedMessage } from 'react-intl';
import './TeamDesc.scss';

function TeamDesc() {
  return (
    <section className='team-desc-section p-y-5 p-x-3'>
      <p>
        <FormattedMessage id='app.team.team-desc-section-line-1'/>
        <br/>
        <FormattedMessage id='app.team.team-desc-section-line-2'/>
        <br/>
        <FormattedMessage id='app.team.team-desc-section-line-3'/>
        <br />
        <FormattedMessage id='app.team.team-desc-section-line-4'/>
      </p>
    </section>
  );
}

export default TeamDesc;
