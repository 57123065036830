import React, { ReactElement, useState } from 'react';
import { IntlProvider } from 'react-intl';
import EN from './en.json';

export type IntlProps = {
    children: ReactElement
}

export function Intl(props: IntlProps) {
    const [locale, setLocale] = useState(navigator.language)
    const [lang, setLang] = useState(EN)

    return (
    <IntlProvider locale={locale} messages={lang}>
        {props.children}
    </IntlProvider>
    )
}