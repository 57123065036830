import axios, { AxiosInstance } from 'axios'
import { MailProvider } from './providers/mailProvider'

class ApiProvider {
    private http: AxiosInstance

    mailProvider: MailProvider
    constructor() {
        this.http = axios.create({
            baseURL: 'https://webservices.tehiru.space/api'
        })

        this.mailProvider = new MailProvider(this.http)
    }
}

const apiProvider = new ApiProvider()
export default apiProvider