import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import Footer from "../footer/Footer";
import pageLoaderEventBus from "../../core/pageLoaderEventBus";
import "./InvestPage.scss";
import { makeContactUsInfo } from "../../api/providers/mailProvider";
import apiProvider from "../../api/apiProvider";
import { Helmet } from "react-helmet";
import { canonialUrls } from "../../routing/paths";

enum MailMessageStatus {
  NONE,
  SENT,
  ERROR,
}

function InvestPage() {
  const [messageStatus, setMessageStatus] = useState(MailMessageStatus.NONE);
  const [formInputValues, setFormInputValues] = useState(makeContactUsInfo());

  async function handleFormSubmit(event: React.FormEvent) {
    event.preventDefault();

    pageLoaderEventBus.load();

    const mailProvider = apiProvider.mailProvider;

    const formCopy = Object.assign({}, formInputValues);
    formCopy.freeText = `**Investment email**: ${formCopy.freeText}`;

    const messageSent = await mailProvider.contactUs(formCopy);
    let newMessageStatus = messageStatus;
    messageSent
      ? (newMessageStatus = MailMessageStatus.SENT)
      : (newMessageStatus = MailMessageStatus.ERROR);

    setMessageStatus(newMessageStatus);
    pageLoaderEventBus.unload();
  }

  function handleInputChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const { name, value } = event.target;
    setFormInputValues((formInputs) => ({
      ...formInputs,
      [name]: value,
    }));
  }

  function buildMessageStatus() {
    let innerContect: JSX.Element;
    switch (messageStatus) {
      case MailMessageStatus.SENT:
        innerContect = (
          <>
            <h2>
              <FormattedMessage id="app.contact-us-page.message-sent.title" />
            </h2>
            <p>
              <FormattedMessage id="app.contact-us-page.message-sent.desc-1" />
              <br />
              <FormattedMessage id="app.contact-us-page.message-sent.desc-2" />
            </p>
          </>
        );
        break;
      case MailMessageStatus.ERROR:
        innerContect = (
          <>
            <h2>
              <FormattedMessage id="app.contact-us-page.message-sent-error.title" />
            </h2>
            <p>
              <FormattedMessage id="app.contact-us-page.message-sent-error.desc" />
            </p>
          </>
        );
        break;
      default:
        innerContect = (
          <>
            <h2>Error</h2>
          </>
        );
    }
    return <div className="d-flex flex-column">{innerContect}</div>;
  }

  return (
    <>
      <Helmet>
        <title>Invest in Tehiru</title>
        <meta
          name="description"
          content="Need to send your payload to space, or want to learn more? Fill out the form below and become a partner."
        ></meta>
        <link rel="canonical" href={canonialUrls.invest} />
      </Helmet>
      <section className="invest-page-contianer p-b-6 p-t-7">
        {messageStatus !== MailMessageStatus.NONE ? (
          buildMessageStatus()
        ) : (
          <div className="invest-grid">
            <div>
              <h2>
                <FormattedMessage id="app.invest-page.title" />
              </h2>
              <p>
                <FormattedMessage id="app.invest-page.desc-1" />
              </p>
              <p>
                <FormattedMessage id="app.invest-page.desc-2" />
              </p>
            </div>
            <form className="invest-page-form" onSubmit={handleFormSubmit}>
              <div className="form-row">
                <input
                  placeholder="First Name*"
                  type="text"
                  className="form-control"
                  name="firstName"
                  data-not-empty={formInputValues.firstName !== ""}
                  value={formInputValues.firstName}
                  onChange={handleInputChange}
                  required
                />
                <input
                  placeholder="Surname*"
                  type="text"
                  className="form-control"
                  name="surname"
                  data-not-empty={formInputValues.surname !== ""}
                  value={formInputValues.surname}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <input
                placeholder="Email Address*"
                type="email"
                className="form-control"
                data-not-empty={formInputValues.email !== ""}
                name="email"
                value={formInputValues.email}
                onChange={handleInputChange}
                required
              />
              <div className="form-row">
                <input
                  placeholder="Phone Number*"
                  type="text"
                  className="form-control"
                  data-not-empty={formInputValues.company !== ""}
                  name="company"
                  value={formInputValues.company}
                  onChange={handleInputChange}
                  required
                />
                <input
                  placeholder="Country*"
                  type="text"
                  className="form-control"
                  data-not-empty={formInputValues.country !== ""}
                  name="country"
                  value={formInputValues.country}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <textarea
                placeholder="Notes"
                className="form-control"
                data-not-empty={formInputValues.freeText !== ""}
                name="freeText"
                value={formInputValues.freeText}
                onChange={handleInputChange}
              />
              <div className="d-flex justify-content-end">
                <button className="btn btn-primary p-x-5">Send</button>
              </div>
            </form>
          </div>
        )}
      </section>
      <Footer />
    </>
  );
}

export default InvestPage;
