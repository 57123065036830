import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import NavBar from "../navBar/NavBar";
import PageLoader from "../pageLoader/PageLolader";
import "./LayoutOutlet.css";
import FloatingButton from "../floatingButton/FloatingButton";
import { paths } from "../../routing/paths";

function LayoutOutlet(props: any) {
  const location = useLocation();

  return (
    <div className="__layout">
      <NavBar />
      <Outlet />

      {location.pathname === paths.invest ? <></> : <FloatingButton />}
      <PageLoader />
    </div>
  );
}

export default LayoutOutlet;
