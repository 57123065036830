export const paths = {
    home: '/',
    team: '/team',
    contactUs: '/contactUs',
    invest: '/invest'
}

const baseUrlForCanonial = `https://www.tehiru.space`
export const canonialUrls = {
    home: `${baseUrlForCanonial}${paths.home}`,
    team: `${baseUrlForCanonial}${paths.team}`,
    contactUs: `${baseUrlForCanonial}${paths.contactUs}`,
    invest: `${baseUrlForCanonial}${paths.invest}`,
}