import React from 'react';
import './HomePage.scss';
import MeetTheTeamSection from './meetTheTeamSection/MeetTheTeamSection';
import PrincipleSection from './principlesSection/PrincipleSection';
import TechSection from './techSection/TechSection';
import ContactUsSection from '../contactUsSection/ContactUsSection';
import Footer from '../footer/Footer';
import HeaderSection from './headerSection/HeaderSection';
import CapabilitiesSection from './capabilitiesSection/CapabilitiesSection';
import { Helmet } from 'react-helmet';
import { canonialUrls } from '../../routing/paths';

function HomePage() {
  return (
    <>
      <Helmet>
        <title>Tehiru</title>
        <meta name="description" content="Tehiru is building next generation rocket infrastructure that supports
          the uber growth of nanosat utilization, worldwide."></meta>
        <link rel="canonical" href={canonialUrls.home} />
      </Helmet>
      <HeaderSection/>
      <PrincipleSection/>
      <CapabilitiesSection/>
      <TechSection/>
      <MeetTheTeamSection/>
      <ContactUsSection/>

      <Footer/>
    </>
  );
}

export default HomePage;
