import React from 'react';
import { FormattedMessage } from 'react-intl';
import './NotFoundPage.scss';

function NotFoundPage() {
  return (
    <div className='page-not-found-container '>
      <h3>
        <FormattedMessage
          id="error.notFound"/>
      </h3>
    </div>
  );
}

export default NotFoundPage;
