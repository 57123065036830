// Only one element can use it at the same time
class PageLoaderEventBus {
    isLoaded: boolean
    listeners: ((isLoaded: boolean) => void)[]

    constructor() {
        this.isLoaded = false
        this.listeners = []
    }
    // set the page loader state a loaded
    load() {
        this.isLoaded = true
        this.notifyListeners()
    }

    unload() {
        this.isLoaded = false
        this.notifyListeners()
    }

    addListener(handler: (isLoaded: boolean) => void) {
        this.listeners.push(handler)
    }

    notifyListeners(){
        this.listeners.forEach(handler => {
            handler(this.isLoaded)
        })
    }
}

const pageLoaderEventBus = new PageLoaderEventBus()
export default pageLoaderEventBus