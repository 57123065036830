import React from 'react';
import { FormattedMessage } from 'react-intl';
import './PrincipleSection.scss';

type coreSectionItem = {
  imageUrl: string,
  headerIntlId: string,
  descIntlId: string,
  alt: string
}

function PrincipleSection() {
  const itemsMap: coreSectionItem[] = [
    {
      imageUrl: 'https://storage.googleapis.com/public-bucket.tehiru.space/images/why-tehiru%2CAffordable.png',
      headerIntlId: 'app.home.why-tehiru.Affordable.header',
      descIntlId: 'app.home.why-tehiru.Affordable.desc',
      alt: 'affordable'
    },
    {
      imageUrl: 'https://storage.googleapis.com/public-bucket.tehiru.space/images/why-tehiru.Timetoorbit.png',
      headerIntlId: 'app.home.why-tehiru.time.header',
      descIntlId: 'app.home.why-tehiru.time.desc',
      alt: 'fast'
    },
    {
      imageUrl: 'https://storage.googleapis.com/public-bucket.tehiru.space/images/why-rehiru.Sustainability.png',
      headerIntlId: 'app.home.why-tehiru.sustainability.header',
      descIntlId: 'app.home.why-tehiru.sustainability.desc',
      alt: 'sustainable'
    },
  ]
  
  function renderItems(): JSX.Element[] {
    return itemsMap.map(item => {
      return (
        <div key={item.imageUrl} className='core-principles-item'>
          <img src={item.imageUrl} alt={item.imageUrl} className='core-principles-item--img'/>
          <h3 className='core-principles-item-header'><FormattedMessage id={item.headerIntlId}/></h3>
          <p className='core-principles-item-p m-t-0'><FormattedMessage id={item.descIntlId}/></p>
        </div>
      )
    })
  }

  return (
      <section className='core-principles-section'>
        <div className='core-principles-section-container container'>
          <h2 className='core-principles-section-header'>
            <FormattedMessage id='app.home.why-tehiru.header'/>
          </h2>
          <div className='core-principles-items-layout'>
            {renderItems()}
          </div>
        </div>
      </section>
  );
}

export default PrincipleSection;
