import { AxiosInstance } from "axios";

export type ContactUsInfo = {
    firstName: string,
    surname: string,
    email: string,
    company: string,
    country: string,
    freeText: string,
}

export function makeContactUsInfo(): ContactUsInfo {
    return {
      firstName: '',
      surname: '',
      email: '',
      company: '',
      country: '',
      freeText: '',
    }
  }

export class MailProvider {
    private http: AxiosInstance
    constructor(http: AxiosInstance) {
        this.http = http
    }

    // TODO maybe return more useful info
    async contactUs(info: ContactUsInfo) {
        try {
            await this.http.post('/contactUs', info)
            return true
        } catch (e) {
            return false
        }

    }
}